<template>
  <div>
    <div class="content">
      <div class="cardTitle">动态信息</div>
      <div class="content">
        <a-row>
          <a-col>
            <a-card>
              <a-descriptions layout="vertical" :column="4">
                <a-descriptions-item label="发布人">
                  {{ detailData.createName }}
                </a-descriptions-item>
                <a-descriptions-item label="动态内容">
                  {{ detailData.content }}
                </a-descriptions-item>
                <a-descriptions-item label="浏览数量">
                  {{ detailData.views }}
                </a-descriptions-item>
                <a-descriptions-item label="点赞数量">
                  {{ detailData.likes }}
                </a-descriptions-item>
                <a-descriptions-item label="发布时间">
                  {{ detailData.createDate }}
                </a-descriptions-item>
                <a-descriptions-item label="是否公开">
                  {{ detailData.isPublic === 1 ? "是" : "否" }}
                </a-descriptions-item>
                <a-descriptions-item label="是否可评论">
                  {{ detailData.isComment === 1 ? "是" : "否" }}
                </a-descriptions-item>
                <a-descriptions-item label="点赞数">
                  {{ detailData.likes }}
                </a-descriptions-item>
                <a-descriptions-item label="浏览量">
                  {{ detailData.views }}
                </a-descriptions-item>
              </a-descriptions>
            </a-card>
          </a-col>
          <a-col class="img-box">
			   <div class="title" v-if="detailData.imgList.length>0">动态图片</div>
            <img
              :src="$ImgUrl(img.url)"
              class="contentImg"
              v-for="(img, index) in detailData.imgList"
              :key="index"
            />
          </a-col>
          <a-col>
            <div class="title">关联话题</div>
            <div class="topic-box">
              <a-button
                class="topic"
                v-for="item in detailData.topicTags"
                :key="item.id"
                >#{{ item.title }}</a-button
              >
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="content">
      <div class="cardTitle">评论列表</div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :scroll="{ x: 1200 }"
        @change="handleTableChange"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: selectionChoosed,
        }"
        :row-key="
          (record, index) => {
            return record.id;
          }
        "
      >
        <span slot="action" slot-scope="text, row">
          <a-space>
            <a
              class="ant-dropdown-link"
              v-if="row.isDelete === 1"
              @click="del([row.id])"
              >删除</a
            >
            <a
              class="ant-dropdown-link"
              v-if="row.isDelete === 0"
              @click="recover([row.id])"
              >恢复</a
            >
          </a-space>
        </span>
      </a-table>
      <div style="margin-bottom: 16px" class="action">
        <!-- 批量操作 -->
        <a-select
          type="primary"
          v-model="activeAction"
          :disabled="!hasSelected"
          :loading="loading"
          style="width: 120px"
          @change="Actions"
          placeholder="请选择操作"
        >
          批量
          <a-select-option v-for="item in ActionsList" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `已选择 ${selectedRowKeys.length} 条` }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
	commentList,
	commentRecover,
	commentDel,
	dynamicInfo,
} from "@/api/operation/dynamic";
export default {
	data() {
		return {
			id: undefined,
			detailData: {},
			tableData: [],
			selectedRowKeys: [],
			activeAction: undefined,
			loading: false,
			ActionsList: [
				{
					value: 1,
					label: "批量删除",
				},
				{
					value: 2,
					label: "批量恢复",
				},
			],
			pagination: {
				current: 1,
				total: 0,
				pageSize: 10,
				showTotal: (total) => `共 ${total} 条`,
				showSizeChanger: true,
				showQuickJumper: true,
			},
			columns: [
				{
					title: "评论内容",
					width: "20%",
					dataIndex: "content",
				},
				{
					title: "评论人名称",
					width: "15%",
					dataIndex: "createName",
				},
				{
					title: "评论人时间",
					width: "15%",
					dataIndex: "createDate",
				},
				{
					title: "点赞数",
					width: "12%",
					dataIndex: "likes",
				},
				{
					title: "是否删除",
					width: "15%",
					dataIndex: "isDelete",
					customRender: function (isDelete) {
						switch (isDelete) {
						case 1:
							return "否";
						case 0:
							return "是";
						}
					},
				},
				{
					title: "操作",
					dataIndex: "action",
					key: "action",
					width: "150",
					fixed: "right",
					scopedSlots: { customRender: "action" },
				},
			],
		};
	},
	mounted() {
		this.id = this.$route.params.data;
		this.getComment();
		this.getData();
	},
	methods: {
		async getData() {
			let res = await dynamicInfo({
				communityDynamicId: this.id,
			});
			this.detailData = res.data;
		},
		async getComment() {
			let res = await commentList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				dynamicId: this.id,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: async () => {
					let res = await commentDel({ commentIds: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getComment();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		recover(ids) {
			this.$confirm({
				title: "是否恢复",
				// okText:'删除',
				// cancelText:'取消',
				icon: "undo",
				onOk: async () => {
					let res = await commentRecover({ commentIds: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getComment();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
			this.getComment();
		},
		Actions(data) {
			this.activeAction = undefined;
			if (data === 1) {
				this.del(this.selectedRowKeys);
			} else {
				this.recover(this.selectedRowKeys);
			}
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
.action {
  margin-top: -50px;
}
.title {
  color: #000000a6;
  font-weight: 500;
  line-height: 19px;
}
.text-content {
  color: #000000d9;
  font-weight: 400;
  line-height: 22px;
  font-size: 16px;
}
.contentImg {
  width: 168px;
  height: 92px;
  border-radius: 4px;
  margin: 10px;
}
.img-box {
  display: flex;
  justify-content: flex-start;
}
.topic-box {
  display: flex;
  justify-content: flex-start;
}
.topic {
  margin: 5px;
  border-radius: 44px;
}
</style>
